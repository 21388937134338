
































import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import Page from '@/components/Page.vue'

@Component<ImprintView>({
  components: {
    Header,
    Page
  },
  // @ts-ignore
  head: {
    title () {
      return {
        inner: `${this.$t('footer.imprint')}`
      }
    }
  }
})
export default class ImprintView extends Vue {}
